import React, { useState } from "react"
import { StaticQuery, graphql } from "gatsby"

// import BackgroundImage from "gatsby-background-image"
import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button"

import FormConnect from "./FormConnect"

const ModalInternet = ({ props, tariffBtnText }) => {
  const [show, setShow] = useState(false)
  const handleToggle = () => setShow(!show)

  return (
    <StaticQuery
      query={graphql`
        query {
          contentfulAsset(id: { eq: "dcf9e483-b82e-5df6-9c6c-e050baf5d3a3" }) {
            fluid(maxWidth: 800) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      `}
      render={data => {
        // const backgroundImage = [
        //   data.contentfulAsset.fluid,
        //   `linear-gradient(35deg, rgba(81, 45, 168, 0.7), rgba(25, 118, 210, 0.5) 60%)`,
        // ].reverse()

        return (
          <>
            <Button
              onClick={handleToggle}
              variant="outline-newtele"
              className="mt-auto"
              block
            >
              {tariffBtnText ? tariffBtnText : `Підключити`}
            </Button>

            <Modal
              {...props}
              show={show}
              onHide={() => setShow(false)}
              aria-labelledby="contactModal"
              className="clearfix"
              centered
            >
              {/* <BackgroundImage fluid={backgroundImage}> */}
              <Modal.Header closeButton>
                <Modal.Title id="contactModal">
                  Заявка на підключення
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <FormConnect />
              </Modal.Body>
              {/* </BackgroundImage> */}
            </Modal>
          </>
        )
      }}
    />
  )
}

export default ModalInternet
