import React from "react"

import Col from "react-bootstrap/Col"
import Card from "react-bootstrap/Card"
// import { RiAlarmLine } from "react-icons/ri"

import ModalService from "../components/global/ModalSerice"

const ServiceCard = ({ serviceTitle, servicePrice, serviceDescription }) => (
  <Col lg={3} md={4} sm={6} xs={12} className="mb-4 service-card">
    {/* <Card className="h-100"> */}
    <Card.Body className="service-card-body h-100">
      <Card.Title className="gradient-text">{serviceTitle}</Card.Title>

      <Card.Text className="">{serviceDescription}</Card.Text>
      <Card.Subtitle className="text-muted service-card-price">
        {servicePrice === 0 ? "безкоштовно" : servicePrice + " грн"}
      </Card.Subtitle>
      <ModalService
        productTitle={serviceTitle}
        productPrice={servicePrice}
        pageSlug={``}
        pageTitle={``}
      />
      {/* <RiAlarmLine className="order-service-icon" /> */}
    </Card.Body>
    {/* </Card> */}
  </Col>
)

ServiceCard.defaultProps = {
  serviceTitle: `Название услуги`,
  servicePrice: `0`,
  serviceDescription: `Описание услуги`,
}

export default ServiceCard
