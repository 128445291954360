import React from "react"
// import { Link } from "gatsby"
import Img from "gatsby-image"
import Card from "react-bootstrap/Card"
// import Button from "react-bootstrap/Button"
import ModalInternet from "../components/global/ModalInternet"

import { IoIosCheckmarkCircleOutline } from "react-icons/io"

const TariffCard = ({
  tariffImage,
  tariffName,
  tariffDescription,
  tariffPrice,
  tariffWorldSpeed,
  tariffUkrSpeed,
  tariffServicesIncluded,
  tariffPurpose,
  tariffBtnText,
}) => (
  <Card className="tariff-card h-100">
    <Img
      fluid={tariffImage}
      className="card-img tariff-image img-responsive"
      alt="cardimage fluid"
    />
    <Card.ImgOverlay className="tariff-image-overlay d-flex align-items-center justify-content-center">
      <div className="tariff-title h5">{tariffName}</div>
    </Card.ImgOverlay>
    <Card.Body className="d-flex flex-column">
      <Card.Text as="div" className="mb-2">
        <span className="tariff-price">{tariffPrice}</span>
        <span className="tariff-price-currency">грн/міс</span>
        {tariffPurpose !== "business" ? (
          <ul className="list-unstyled tariff-list">
            <li className="tariff-offer my-3">
              <div className="tariff-offer-price-wrapper">
                <span className="tariff-offer-price">
                  {Math.round((parseInt(tariffPrice) * 6) / 7)}
                </span>
                <span className="tariff-offer-currency">грн/міс</span>
              </div>
              <div className="tariff-offer-divider"></div>
              <div className="tariff-offer-description">від 6 міс</div>
            </li>
            <li className="tariff-offer">
              <div className="tariff-offer-price-wrapper">
                <span className="tariff-offer-price">
                  {Math.round((parseInt(tariffPrice) * 10) / 12)}
                </span>
                <span className="tariff-offer-currency">грн/міс</span>
              </div>
              <div className="tariff-offer-divider"></div>
              <div className="tariff-offer-description">від 10 міс</div>
            </li>
          </ul>
        ) : (
          ``
        )}
        <div className="tariff-card-description my-3">{tariffDescription}</div>
        <ul className="list-unstyled tariff-list">
          <li>
            <IoIosCheckmarkCircleOutline className="tariff-list-icon" />
            Безлімітний
          </li>
          <li>
            <IoIosCheckmarkCircleOutline className="tariff-list-icon" />
            До {tariffWorldSpeed} Мбіт/с світ
          </li>
          <li>
            <IoIosCheckmarkCircleOutline className="tariff-list-icon" />
            До {tariffUkrSpeed} Мбіт/с Україна
          </li>
          {tariffServicesIncluded ? (
            <li>
              <IoIosCheckmarkCircleOutline className="tariff-list-icon" />
              {tariffServicesIncluded[0].serviceName}
            </li>
          ) : (
            ``
          )}
        </ul>
      </Card.Text>
      {/* <Button
        as={Link}
        to="/"
        variant="outline-newtele"
        className="mt-auto"
        block
      >
        Підключити
      </Button> */}
      <ModalInternet tariffBtnText={tariffBtnText} />
    </Card.Body>
  </Card>
)

TariffCard.defaultProps = {
  tariffImage: ``,
  tariffName: `Название Тарифа`,
  tariffDescription: `Описание тарифа на несколько строк`,
  tariffPrice: `100`,
  tariffWorldSpeed: `100`,
  tariffUkrSpeed: `100`,
  tariffServicesIncluded: ``,
  tariffPurpose: ``,
  tariffBtnText: `Підключити`,
}

export default TariffCard
